@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.dragging {
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.linkBottom {
	transition: left 0.2s ease-in-out;
	/* Agrega una transición a la propiedad left */
}

input[type="color"] {
	opacity: 0;
	display: block;
	width: 32px;
	height: 32px;
	border: none;
}

/* Personalización para Firefox */
/* Establecer el grosor de la barra y los colores */
div::-webkit-scrollbar {
	width: 10px;
}

div::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 6px;
}

div::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

.confeti-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	z-index: 9999; /* Asegúrate de que el contenedor esté en la parte superior */
  }
  

.custom-snackbar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #333; 
	color: white;
	text-align: center;
	padding: 12px; 
	z-index: 9999;
  }
